import "../css/widget.css";
import * as jsonify from 'jsonify-that-feed';
import VueSanitize from "vue-sanitize";

Vue.component("NewsfeedWidget", {

	template: `<div class="widget-newsfeed" ref="feedList"><slot /></div>`,

	props: {
		address: {type: String, required: true},
		limit: {type: Number, required: false},
		allowed: {type: Array, required: false},
		preview: {type: Boolean},
		showdate: {type: String},
		showauthor: {type: String},
		showcategory: {type: String},
		showcontent: {type: String},
		showsummary: {type: String},
		showlink: {type: String},
		xmlelement: {type: String},
		dateselect: {type: String},
		csscentry: {type: String},
		cssctitle: {type: String},
		cssclink: {type: String},
		fmtMore: {type: String},
		fmtAuthor: {type: String}
	},

	mounted() {
		if(this.preview){
			this.$refs.feedList.className += ' preview';
		}
		this.fetchFeed(this.address).then(text => {
			this.$nextTick(() => {
				const feed = jsonify.toJson(text);
				// console.log(feed);

				Vue.use(VueSanitize, {
					allowedTags: this.allowed,
					allowedAttributes: {
						'a': ['href','rel'],
						'img': ['src', 'srcset', 'alt', 'title', 'loading', 'sizes']
					}
				});

				let markup = '';
				const entries = feed.items.slice(0, this.limit);
				entries.forEach(entry => {
					const link = entry.url;
					const summary = this.$sanitize(entry.summary['#text'] ?? entry.summary ?? '');
					const article = this.extractArticle(entry);

					markup += `<article class="widget-newsfeed-item ${this.csscentry ?? ''}">`;
					markup += `<h1 class="title ${this.cssctitle ?? ''}">${
							(!link) ? entry.title : `<a href="${link}" target="_blank">${entry.title}</a>`
							}</h1>`;

					if( this.showcontent && article ) {
						if( this.showsummary && entry.hasOwnProperty('summary') ) {
							markup += `<details><summary>${summary}</summary>${article}</details>`;
						} else {
							markup += `<div>${article}</div>`;
						}
					}
					if( this.showdate || this.showauthor || this.showlink || this.showcategory ) {
						markup += '<footer>';
						if( this.showdate || this.showauthor || this.showcategory ) {
							markup += '<div class="meta">';
							let selectedDate= entry.date_modified;
							if( entry.date_published && (this.dateselect === 'published' || !selectedDate) )
								selectedDate = entry.date_published;
							if( this.showdate && selectedDate ) {
								const localDate = new Date(Date.parse(selectedDate));
								markup += `<p class="date"><time datetime="${localDate.toISOString()}">${
									localDate.toLocaleDateString(undefined, {dateStyle: 'long'})
									+ ' ' +
									localDate.toLocaleTimeString(undefined, {timeStyle: 'short'})
								}</time></p>`;
							}
							if( this.showauthor && entry.authors.length >0 ) {
								markup += `<p class="authors"><span class="prefix">${
									this.fmtAuthor.replace('%s', '') ?? 'By'
								}</span> `;
								entry.authors.forEach(author => {
									markup += `<span> ${author.name}</span>`;
								});
								markup += '</p>';
							}
							if( this.showcategory && entry.tags ) {
								markup += `<p class="categories">`;
								entry.tags.forEach(tag => {
									markup += `<span> ${tag}</span>`;
								});
								markup += '</p>';
							}
							markup += '</div>';
						}
						if( this.showlink && link ) {
							markup += `<p class="link ${this.cssclink ?? ''}">`
							markup += `<a href="${link}" target="_blank">${this.fmtMore ?? 'Read more'}…</a></p>`;
						}
						markup += '</footer>';
					}
					markup += '</article>';
				});
				this.$refs.feedList.innerHTML = markup;
			});
		});
	},

	methods: {
		extractArticle(entry) {
			let article, summary = entry.summary['#text'] ?? entry.summary ?? '';
			switch (this.xmlelement) {
				case 'content':
					article = entry.content_html;
					break;
				case 'description':
					article = entry.content_text;
					break;
				case 'summary':
					article = summary;
					break;
				default:
					return null;
			}
			article = article ?? entry.content_html ?? (summary ?? entry.content_text);
			return this.$sanitize(article);
		},
		fetchFeed(address) {
			return fetch(address, {
				'headers':{
					'Accept': 'application/atom+xml,application/xml,text/xml,*/*',
					'User-Agent': 'Plentymarkets/NewsfeedWidget'
				}
			}).then(response => {
				return (response.ok)? response.text() : '';
			}).catch(error => {
				console.error(error);
			});
		}
	}
});
